<template>
    <div>
        <el-dialog
            title="驳回"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                    <el-form-item prop="reject_reason" label="驳回原因">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 4 }"
                            placeholder="请输入内容"
                            maxlength="500"
                            show-word-limit
                            v-model="form.reject_reason">
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="remark" label="备注">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 4 }"
                            placeholder="请输入内容"
                            maxlength="500"
                            show-word-limit
                            v-model="form.remark">
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="reject_media_list" label="图片(选填)">
                        <le-upload-img-wx 
                            ref="reject_media_list" 
                            uploadWxType='complaint'
                            label="" 
                            :extraParams="{
                                'mchId': form.mchId
                            }"
                            :limit="4" 
                            :imgList.sync="form.reject_img_list" 
                            :mediaList.sync="form.reject_media_list"></le-upload-img-wx>
                        <div class="tips">
                            <span>1.最多上传4张图片，每张图片不超过5M。</span>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import LeUploadImgWx from '../../components/form/le-upload-img-wx.vue';
    export default {
        components: {
            LeUploadImgWx
        },
        data() {
            return {
                dialogVisible: false,
                form: {
                    action: '',//操作类型
                    mchId: '',
                    reject_reason: '',
                    reject_media_list: [],
                    reject_img_list: [],
                    complaintId: '',
                    remark: ''
                },
                rules: {
                    reject_reason: [{required: true, message:'请输入驳回原因', trigger: 'blur'}]
                }
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                    this.form.reject_img_list = []
                    this.$refs['reject_media_list'].fileList = []
                }
            }
        },
        methods:{
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let reqData = {
                            ...this.form
                        }
                        this.$confirm('是否确认提交？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            this.$Axios._post({
                                url: this.$Config.apiUrl.complaintUpdateRefund,
                                params: reqData,
                            })
                            .then(res => {
                                if(res.result.code == 0){
                                    this.dialogVisible = false
                                    this.$message.success('操作成功')
                                    this.$emit('handlerSuccess');
                                }
                            })
                            .catch((error) => {
                                this.$message.error(error.result.message)
                            });
                        }).catch(_=>{ })
                    }
                })
                
            },

        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>