<template>
    <div>
        <el-dialog
            title="订单退款"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="600px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="140px">
                <div class="a-flex-rfsc" v-if="form.packageType == 5">
                    <span>购买剩余：{{ form.buyPoints }}，赠送剩余：{{ form.giftPoints }}；</span>
                    <span class="a-ml-20">总量：{{ form.packgeSoybean + form.packgeSoybeanGift }}（赠送：{{ form.packgeSoybeanGift }}）</span>
                </div>
                <div class="a-mtb-24" v-if="form.packageType == 5">
                    <span>订单实付金额：￥{{ util.numFormat(form.payAmount) }}</span>
                </div>
                <el-form-item label="退款金额" prop="refundAmount">
                    <jd-input-price v-model="form.refundAmount"></jd-input-price>
                </el-form-item>
                <el-form-item :label="'退款'+$Config.discountTypeDic[item.discountType]" prop="pointsAmount" v-for="(item,index) in form.refundDiscountVoList" :key="index">
                    <jd-input-price v-model="item.refundAmount"></jd-input-price>
                </el-form-item>
                <el-form-item label="是否解除绑定关系" prop="returnGoods" v-if="form.packageType != 5">
                    <el-radio :label="1" v-model="form.returnGoods">是</el-radio>
                    <el-radio :label="0" v-model="form.returnGoods">否</el-radio>
                </el-form-item>
                <el-form-item label="退款原因" prop="refundMsg">
                    <el-input
                        type="textarea"
                        :rows="4"
                        maxlength="80"
                        placeholder="请输入内容"
                        v-model="form.refundMsg">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        <order-refound-retry ref="orderRefoundRetry"></order-refound-retry>
    </div>
</template>

<script>
    import util from '../../../utils/util'
    import orderRefoundRetry from './orderRefoundRetry.vue';
    export default {
    components: { orderRefoundRetry },
        data() {
            return {
                util: util,
                dialogVisible: false,
                form: {
                    payAmount: '',//套餐实付金额
                    packgeSoybean: 0,//当前套餐购买时毛豆数量
                    packgeSoybeanGift: 0,//当前套餐购买时赠送毛豆数量
                    buyPoints: 0,//当前套餐剩余毛豆数量
                    giftPoints: 0,//当前套餐剩余赠送毛豆数量
                    refundAmount: '',
                    returnGoods: 1,
                    refundMsg: '',
                    packageType: '',//当前的套餐种类
                    refundDiscountVoList: [], //优惠信息
                },
                id: '',
                orderSn: '',
                rules:{
                    refundAmount: [{required: true, message:'请输入退款金额', trigger: 'blur'}],
                    refundMsg: [{required: true, message:'请输入退款原因', trigger: 'blur'}]
                }
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
                if(val && this.form.packageType == 5) {
                    this.remainPoints()
                }
            },
        },
        methods:{
            submit () {
                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                        let checkResult = await this.checkRules(this.form.refundDiscountVoList)
                        if(!checkResult) {
                            return
                        }
                        let reqForm = {
                            ...this.form,
                            refundAmount: Number((this.form.refundAmount*100).toFixed(0))
                        }
                        reqForm.refundDiscountVoList = reqForm.refundDiscountVoList.map(item=>{
                            return {
                                ...item,
                                refundAmount: Number((item.refundAmount*100).toFixed(0))
                            }
                        })
                        this.$Axios._post({
                            url: this.$Config.apiUrl.superOrderRefund,
                            method: "post",
                            params: {
                                ...reqForm,
                                refundPoints: this.form.buyPoints + this.form.giftPoints,
                                id:this.id
                            }
                        })
                        .then(res=>{
                            if (res.result.code == 0) {
                                this.$message.success('操作成功')
                                this.dialogVisible = false
                                this.$emit('oprateFinish')
                            } else if(res.result.code == 1314) {
                                this.dialogVisible = false
                                this.$refs['orderRefoundRetry'].dialogVisible = true
                                this.$refs['orderRefoundRetry'].form = {
                                    ...res.result.data.record
                                }
                                this.$message.error(res.result.message)
                            } else {
                                this.$message.error(res.result.message)
                            }
                        })
                        .catch(err=>{
                            
                        })
                    }
                })
            },
            // 获取当前套餐储值卡剩余毛豆数量
            remainPoints () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.remainPoints,
                    method: "get",
                    params: {
                        orderSn: this.orderSn
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.form.buyPoints = res.result.data.buyPoints || 0
                        this.form.giftPoints = res.result.data.giftPoints || 0
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            },
            checkRules (reqData) {
                return new Promise((resolve, reject) => {
                    if(reqData.length && reqData){
                        for (let index = 0; index < reqData.length; index++) {
                            const item = reqData[index];
                            if(Number(item.refundAmount) > item.originRefundAmount){
                                this.$message.error(this.$Config.discountTypeDic[item.discountType] + '的退款金额不得大于最大可退金额')
                                resolve(false)
                                return
                            }
                        }
                        resolve(true)
                    }else{
                        resolve(true)
                    }
                })
            },
        }
    }
</script>